import { openBrowserTo, createSource } from "~/lib/bindings";
import { fetchProfile, fetchProfiles } from "~/lib/streamlabs-api";
import { getUniqueSourceName } from "@/lib/themes/obs";
import widgetData from "~/data/widgets.json";

export const widgetTypes: { id: WidgetThemeType; name: string }[] = [
  { id: "alertbox", name: "Alert Box" },
  { id: "chatbox", name: "Chat Box" },
  { id: "credits", name: "Credits" },
  { id: "donationticker", name: "Donation Ticker" },
  { id: "eventlist", name: "Event List" },
  { id: "goals", name: "Goals" },
  { id: "streamboss", name: "Stream Boss" },
  { id: "tipjar", name: "Tip Jar" },
  { id: "viewercount", name: "Viewer Count" },
];

export const useWidgetsStore = defineStore("widgets", () => {
  console.log("widgets.store");
  const base = ref<Widget[]>(widgetData);

  const profiles = fetchProfiles().data;
  const activeProfileId = computed(() => profiles.value?.active_profile);
  const activeProfile = ref<WidgetProfileResponse>();

  watch(activeProfileId, (activeProfileId) => {
    if (activeProfileId) {
      fetchProfile(activeProfileId).then(({ data }) => {
        if (data.value) {
          activeProfile.value = data.value;
        }
      });
    } else {
      activeProfile.value = undefined;
    }
  });

  const widgetUrls = computed(() => {
    const widgetUrls: Record<string, string> = {};

    const rx = new RegExp(`\/${activeProfileId.value}$`);

    if (activeProfile.value) {
      for (const [widgetId, widgetUrl] of Object.entries(
        activeProfile.value.widgets,
      )) {
        const url = new URL(widgetUrl);
        url.pathname = url.pathname.replace(rx, "");
        widgetUrls[widgetId.replace("_", "-")] = url.toString();
      }
    }

    return widgetUrls;
  });

  const all = computed(() => {
    const loaded = !isEmpty(widgetUrls.value);
    const all = base.value
      .map((widget) => {
        return {
          ...widget,
          widget_url: widgetUrls.value[widget.id],
        };
      })
      .filter((widget) => !loaded || !!widget.widget_url);

    return all;
  });

  const featured = computed(() =>
    all.value.filter((widget) => widget.featured),
  );

  const addWidgetToCurrentScene = async (widget: Widget) => {
    if (widget.widget_url) {
      createSource({
        type: "browser_source",
        name: await getUniqueSourceName(widget.name), // generateRandomString(),
        //
        settings: {
          ...widget.settings,
          url: widget.widget_url,
        },
      });
    }
  };

  const openWidgetSettings = (widget: Widget) => {
    if (widget.settings_url) {
      openBrowserTo(widget.settings_url);
    }
  };

  return {
    all,
    featured,

    addWidgetToCurrentScene,
    openWidgetSettings,
  };
});
